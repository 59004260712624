/* .searchInput.ant-input:placeholder-shown{
    font: normal normal normal 14px/19px Open Sans;
    color: #858282;
} */
.ant-table-column-title{
    font-family:'Inter';
    font-weight:700;
    font-style: normal;
    font-size: 12px !important;
}
.ant-input:placeholder-shown {
    text-overflow: ellipsis;
    font-family: "Inter";
    color: #868B8F;
    line-height: 17px;
}
table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 13px 5px 13px 5px;
  left: -5px;
}
  .ant-table-thead .ant-table-cell {
    background-color: white;
  }
  .ant-table.ant-table-small {
    font-size: 13px;
}
.ant-btn:hover{
  color: #192228;
}
.ant-checkbox {
  margin-top: 4px;
}
/* .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 0px;
} */
/* .ant-checkbox-inner{
  border: 1.5px solid rgba(12, 23, 58, 0.5) !important;
  } */
  .ant-checkbox-checked::after {
  border: none !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0c173a !important;
  border-color: #0c173a !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0c173a !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #0c173a !important;
}
.ant-checkbox-inner{
  border-radius: "3px" !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after{
  top:"6px" !important;
}